<template>
    <public-frame-dark class="d-flex flex-column" style="min-height: 460px;">
        <div >
            <div class="text-center">
                <h1 style="font-size: 8em; color: #ffffff">403</h1>
            </div>
            <div class="text-center mt-4">
                <h1 style="font-size: 2rem;" class="text-danger">{{$t("ERROR.UNAUTHORIZED")}}</h1>
                <br />
                <h3><a href="javascript://void(0);" @click="$router.push('/portal')" style="cursor: pointer; text-decoration: none;color: black">{{$t('TITLE.GO-BACK')}}</a></h3>
            </div>
        </div>
    </public-frame-dark>
</template>

<script>
import PublicFrameDark from "@/components/mobile-public-frame-dark";

export default {
    name: "notfound",
    components: {PublicFrameDark}
}
</script>
