<template>
    <div class="wh-100 mobile-portal ">
        <section class="header">
            <div class="search-bar-wrap">
                <nut-textinput class="search-bar"
                               :placeholder="$t('TITLE.SEARCH')"
                               :clearBtnPersonnal="true"
                               v-model="search_filter">
                    <template slot="clearBtn">
                        <nut-icon type="circle-cross" color="#FFFFFF"></nut-icon>
                    </template>
                </nut-textinput>
                <div class="language" @click="setLocale">
                    <img src="@/assets/images/globe.svg" width="22" height="22" alt="globe"/>
                </div>
            </div>
            <div class="header-wrap">
                <section class="header-menu">
                    <div v-for="x in menu" :key="x.key" class="header-menu-item" :class="{active:pageKey===x.key}"
                         @click=" pageKey!==x.key && $router.push(x.url)">
                        {{ english ? x.english : x.name }}
                    </div>
                    <div class="menu-indicator" :style="{left:indicate_left,color:'red'}"></div>
                </section>
            </div>
        </section>
        <section class="content-wrap">
            <keep-alive>
                <router-view :key="pageKey"></router-view>
            </keep-alive>
        </section>
    </div>
</template>
<script>
import CommonMixin from "@/common/mixins/common-mixin";
import "@/plugins/nutui"

export default {
    name: "public",
    mixins: [CommonMixin],
    data() {
        return {
            search_filter: "",
            menu: [
                {name: '推荐', key: '主页', english: 'Portal', url: 'portal', index: 0},
                {name: '直播', key: '直播', english: 'Live', url: 'live', index: 1},
                {name: '回放', key: '回播', english: 'Replay', url: 'playback', index: 2},
                {name: '档案', key: '档案', english: 'Profile', url: 'profile', index: 3}
            ],
        }
    },
    watch: {
        search_filter(newVal) {
            this.setFilter(newVal);
        },
        filter(value) {
            if (value !== this.search_filter) {
                this.search_filter = value;
            }
        }
    },
    components: {},
    computed: {
        filter() {
            return this.process.filter;
        },
        pageKey() {
            if (this.$route.meta) {
                if (this.$route.meta.model) return this.$route.meta.model;
                else return this.$route.name;
            }
            return '';
        },
        indicate_left() {
            const key = this.pageKey;
            for (let i = 0; i < this.menu.length; i++) {
                if (this.menu[i].key === key) {
                    if (i === 0) return "0";
                    return "calc(  ((100% - 220px) / 3 + 55px) * " + i + ")"
                }
            }
            return "0"
        },
    },
    created() {
        this.setWindowTitle();
    }

}
</script>

<style lang="scss">
.mobile-portal {
    display: flex;
    flex-direction: column;

    .header {
        background-color: $--public-highlight;
        flex: 0 0 94px;

        .search-bar-wrap {
            display: flex;
            padding: 9px 5px 5px 5px;

            .search-bar {
                background-color: transparent;
                flex: 1;

                &.nut-textinput {
                    input {
                        position: relative;
                        height: 36px;
                        -webkit-border-radius: 20px;
                        border-radius: 20px;
                        padding-left: 40px;
                        padding-right: 40px;
                        background-color: RGBA(255, 255, 255, 0.2);
                        background-image: url("~@/assets/images/search-box-bg.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: 5px 0;
                        border-color: transparent;
                        color: white;

                        &::placeholder {
                            color: RGBA(255, 255, 255, 0.6);
                        }
                    }

                    &.nut-textinput {
                        .nut-textinput-clear {
                            svg {
                                fill: currentColor;
                            }
                        }
                    }
                }
            }

            .language {
                padding-left: 10px;
                font-weight: bold;
                color: #dddddd;
                line-height: 30px;
                padding-right: 5px;
                margin-left: 10px;
            }
        }

        .header-wrap {
            padding: 0 10px;

            .header-menu {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 44px;
                position: relative;

                .header-menu-item {
                    color: #fff2f2;
                    font-size: 16px;
                    width: 55px;
                    text-align: center;
                    transition: font-size 300ms ease-in;

                    &.active {
                        font-size: 18px;
                        color: white;
                    }
                }

                .menu-indicator {
                    color: white;
                    position: absolute;
                    bottom: 5px;
                    width: 45px;
                    margin-left: 5px;
                    height: 2px;
                    background-color: white;
                    transition: 200ms left ease-in;
                }
            }
        }

    }


    .content-wrap {
        flex: 1;
        background-color: #f1f1f1;
        overflow: hidden;
    }

}
</style>
